// db.js
import Dexie from 'dexie';
import {articleDB} from "src/services/sync/articleDB";

export const db = new Dexie( 'rehappDB' );

console.log( 'INITIALIZE DB' );

db.version( 54 ).stores( {
    translations      : '++id, identifier',
    pages             : '++id, component',
    defaultpages      : '++id, component',
    categories        : '++id, categorykey',
    forms             : '++id, formtype',
    milestones        : '++id',
    articles          : '++id, uuid, parent_id, mainchapter, translation_id, default, page, childpages',
    specialarticles   : '++id, identifier',
    favorites         : '++id, article_uuid',
    articleslist      : '++id, uuid, page',
    pictureslist      : '++id',
    sync_log          : '++id, uuid',
    my_children       : '++id, uuid',
    client_milestones : '++id, uuid, client_uuid, synced',
    version           : '',
    form_articles     : '++id, formtype, articletype',
    generic_clients   : '++id, uuid, clientname',
    hiapp_form        : '++id',
    hiapp_client_forms: '++id, uuid, client_uuid',
} );

db.getForm = async function ( formID ) {
    let form = await db.forms.where( {id: formID} ).first()

    return form
}

db.getClientForm = async function () {
    let form = null
    let forms = await db.forms.toArray()
    forms.forEach( f => {
        if ( f.formtype === 'DEFAULT' ) form = f
    } )
    return form.formpages.find( f => f.formtype === 'CLIENTFORM' )
}

db.getCategories = async function () {
    let categories = await db.categories.toArray()
    return categories
}

db.getCategory = async function ( categoryID ) {
    let category = await db.categories.where( {id: categoryID} ).first()
    return category
}

db.getCategoryItems = async function ( categoryID ) {
    let category = await db.categories.where( {id: categoryID} ).first()

    let trees = [];
    if ( !category ) return []

    let categoryItems = []
    category.categoryitems.forEach( async ci => {
        if ( ci.trees.length > 0 ) {
            ci.trees.forEach( tree => {
                trees.push( {
                    translation_id: tree.editortext.translation_id
                } )
            } )
        }

        categoryItems.push( {
            id       : ci.id,
            imagepath: ci.imagepath,
            label    : ci.translation ? ci.translation.translation : '',
            checked  : false,
            trees    : trees
        } )
    } )
    return categoryItems
}

db.getFormCategories = async (categoryID) => {
    try {
        const category = await db.categories.where({id: categoryID}).first()
        if (!category) return []

        const categoryItems = await Promise.all(category.categoryitems.map(async ci => {
            try {
                let followup = null
                if (ci?.categoryfollowup) {
                    followup = {
                        component: ci.categoryfollowup.followuptype,
                        answer: null
                    }
                    if (ci.categoryfollowup.category_id) {
                        followup.category_id = ci.categoryfollowup.category_id
                        followup.items = await db.getCategoryItems(followup.category_id)
                    }
                }

                return {
                    id: ci?.id,
                    imagepath: ci?.imagepath,
                    label: ci?.translation?.translation || '',
                    followup
                }
            } catch (itemError) {
                console.error('Error processing category item:', itemError)
                return null
            }
        }))

        return categoryItems.filter(item => item !== null)
    } catch (error) {
        console.error('Error fetching form categories:', error)
        return []
    }
}


db.getFormByFormkey = async function ( formkey ) {
    let forms = await db.forms.toArray()

    let form = forms.find( f => f.formkey == formkey )

    return form
}

// db.getTranslation = async function ( identifier ) {
//   let translation = await db.translations.where( {identifier: identifier} ).first()
//
//   return translation.translation
// }

db.getTranslation = async function ( identifier ) {
    let translation = await db.translations.where( {identifier: identifier} ).first()

    if ( !translation ) return 'NB'

    return translation.translation
}

db.getAllTranslations = async function () {
    let translations = await db.translations.toArray()

    return translations
}

db.getPageByComponent = async function ( component ) {
    let page = await db.pages.where( {component: component} ).first()

    return page
}

db.getCategoryByKey = async function ( categorykey ) {
    let category =
            await db.categories.where( {'categorykey': categorykey} ).first()

    return category
}

db.search = async function ( prefixes ) {
    return db.transaction( 'r', db.articles, function* () {
        // Parallell search for all prefixes - just select resulting primary keys
        const results = yield Dexie.Promise.all( prefixes.map( prefix =>
            db.articles
                .where( 'page' )
                .startsWith( prefix )
                .primaryKeys() ) );

        // Intersect result set of primary keys
        const reduced = results
            .reduce( ( a, b ) => {
                const set = new Set( b );
                return a.filter( k => set.has( k ) );
            } );

        // Finally select entire documents from intersection
        return yield db.articles.where( ':id' ).anyOf( reduced ).toArray();
    } );
}

db.getTranslationsByIdentifier = async function ( identifiers ) {
    let translations = []
    await identifiers.map( identifier => {
        db.getTranslation( identifier )
            .then( async t => {
                await translations.push( {identifier: identifier, translation: t} )
            } )
    } )
    return translations
}

db.getFormArticle = async function ( formType, articletype ) {
    let formArticle = await db.form_articles.where( {
        formtype   : formType,
        articletype: articletype,
    } ).first()

    return formArticle
}

  ,
  db.getFormPageById = async (formPageId) =>{
      try {
        // Get all forms first
        const forms = await db.forms.toArray();

        // Search through all forms and their formpages
        for (const form of forms) {
          const foundFormPage = form.formpages.find(formpage => formpage.id === formPageId);
          if (foundFormPage) {
            return foundFormPage;
          }
        }

        return null; // Return null if not found
      } catch (error) {
        console.error('Error finding formpage:', error);
        return null;
      }
}


db.clearTables = async () => {
    console.log( 'CLEAR ALL TABLES' );

    try {
        let tables = [
            'articles',
            'articleslist',
            'categories',
            'client_milestones',
            'defaultpages',
            'favorites',
            'form_articles',
            'forms',
            'generic_clients',
            'hiapp_client_forms',
            'hiapp_form',
            'milestones',
            'my_children',
            'pages',
            'pictureslist',
            'specialarticles',
            'sync_log',
            'translations'
        ]
        tables.forEach( async ( table ) => {
            try {
                return await db[table].clear()
            } catch (error) {
                throw new Error( error )
            }
        } )
    } catch (error) {
        throw new Error( error )
    }
}






