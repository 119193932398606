<script>

import {defineComponent, ref, watch} from 'vue';
import {mapState, mapGetters, mapActions, useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import {speechService} from "src/services/speech/speechService"
import Version2To3 from 'src/services/conversions/Version2To3'
import {setCssVar} from 'quasar'
import {persistMobiledata} from "src/services/sync/persistMobiledata";
import {db} from "src/services/indexedDB/db";
import {formsDB} from "src/services/indexedDB/formsDB";
import Router from "src/router"
import {Loading, QSpinnerGears, Notify} from 'quasar'
import {conversion204} from 'src/services/conversion/version204.js'
import {appRegistration} from 'src/services/logging/appRegistration.js'
import {errorLogging} from "src/services/logging/errorLogging";

export default defineComponent( {
  name: 'App',
  data() {
    return {
      online    : false,
      refreshing: false,
    }
  },
  errorCaptured( error, compInst, errorInfo ) {
    errorLogging.logError(error)
  },
  computed: {
    ...mapState( ['appData', 'settings', 'onlinestatus', 'loggedIn'] )
  },
  async created() {
    console.log( 'THE APP IS STARTING' );

    const appInstall = await appRegistration.getAppInstall();

    Router().replace( '/' )

    this.setOnlinestatus( navigator.onLine )

    if ( this.checkNeedsInitializing() === true ) return this.initializeTheApp(appInstall.uuid)

    localStorage.setItem( 'APP-ID', appInstall.uuid )

    this.serviceWorkerWatch()

    await this.checkVersion()

    localStorage.removeItem( 'STARTUPBUSY' )

    if ( !localStorage.getItem( 'INITIALIZED' ) ) return Router().replace( '/initialize' )

    let appData = localStorage.getItem( 'APP-DATA' )
    appData = JSON.parse( appData )
    this.setAppData( appData )

    let settings = localStorage.getItem( 'settings' )
    if ( settings ) {
      settings = JSON.parse( settings )
      this.setSettings( settings )
    }
    if ( this.appData && this.appData.text2speech ) {
      speechService.init()
    }

    if ( this.settings.fontSize ) {
      document.getElementsByTagName( 'html' )[0].style.fontSize = this.settings.fontSize + 'px'
    }

    // Make all translation available via VUEX
    this.setTranslations()

    window.addEventListener( "online", async () => {
      this.setOnlinestatus( true )
      Notify.create( {
        message: 'You are back online',
        color  : 'green',
        icon   : 'wifi'
      } )
      await persistMobiledata.prepareSync()
      await errorLogging.registerErrors()
    } );

    window.addEventListener( "offline", () => {
      this.setOnlinestatus( false )
      Notify.create( {
        message: 'You are offline',
        color  : 'red',
        icon   : 'wifi_off'
      } )
    } );

    setTimeout( () => {
      this.$router.push( '/home' )
    }, 500 )
  },
  watch  : {
    appData() {
      this.setDefaultColors()
    }
  },
  methods: {
    ...mapActions( ['setOnlinestatus', 'getInitApplicationByUrl', 'setSettings', 'setTranslations', 'setAppData', 'getVersion'] ),
    initiateErrorLogging() {},
    async initializeTheApp(appUUID) {
      try {
        await db.clearTables()
        console.log( 'TABLES CLEARED' );
      } catch (error) {
        console.log( error )
      }

      localStorage.clear()
      localStorage.setItem( 'INITIALIZINGBUSY', 1 )
      localStorage.setItem( 'STARTUPBUSY', 1 )
      localStorage.setItem( 'APP-ID', appUUID )

      Loading.show( {
        spinner: QSpinnerGears,
        message: 'The app is starting, if you have a slow internet connection this might take a minute...'
      } )
      speechService.init()
      await this.getInitApplicationByUrl()

      localStorage.removeItem( 'STARTUPBUSY' )

      this.$router.replace( '/initialize' )

      Loading.hide()
    },
    async checkVersion() {
      if ( !this.onlinestatus ) return

      let result = await this.getVersion()
//       if ( !result ) return this.setTimeoutForCheckVersion()

      let version = result.data.version

      let localVersion = localStorage.getItem( 'version' )

      await this.conversion( version )
      if ( !localVersion || localVersion !== version ) {
        localStorage.setItem( 'version', version )
        await this.conversion( localVersion, version )
        //location.reload( true )
        return
      }

      this.setTimeoutForCheckVersion()
    },
    async conversion( version ) {
      if( ! version ) return
      let currentVersion = version.substring( 0, 4 );

      if ( currentVersion == '2.04' && this.loggedIn ) await conversion204.doConversion()
    },
    checkNeedsInitializing() {
      if ( !localStorage.getItem( 'version' ) ) return true
      if ( !localStorage.getItem( 'INITIALIZED' ) ) return true
      if ( localStorage.getItem( 'INITIALIZINGBUSY' ) == 1 ) return true
    },
    setTimeoutForCheckVersion() {
      setTimeout( () => {
        this.checkVersion()
      }, 1000 * 60 * 60 * 24 ) // Check every 24 hours for an update
    },
    serviceWorkerWatch() {
      document.addEventListener(
          'swUpdated', this.showRefreshUI, {once: true}
      )
      if ( navigator.serviceWorker ) {
        navigator.serviceWorker.addEventListener(
            'controllerchange', () => {
              if ( this.refreshing ) return;
              this.refreshing = true;
            }
        );
      }
    },
    showRefreshUI( e ) {
      // Display a button inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
//       this.updateExists = true;
      this.showUpdateDialog()
    },
    showUpdateDialog() {
//       Dialog.create( {
//         dark      : true,
//         title     : 'Confirm',
//         message   : 'There is a new version of the RehApp, would you like to update the app?',
//         cancel    : true,
//         persistent: true
//       } ).onOk( () => {
      this.refreshApp()
//       } )
    },
    refreshApp() {
      // Handle a user tap on the update app button.
      this.updateExists = false;
      // Protect against missing registration.waiting.
      if ( !this.registration || !this.registration.waiting ) {
        return;
      }
      this.registration.waiting.postMessage( 'skipWaiting' );
    }
  }
} )
</script>


<template>
  <router-view/>
</template>

