/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 24/02/2025
 * Time: 11:46
 */

import {formsDB} from './formsDB'
import {db} from './db'
import {v4 as uuidv4} from "uuid"
import {helpers} from "src/services/helpers"
import {date, Notify} from 'quasar'
import {persistMobiledata} from "src/services/sync/persistMobiledata"


export const malawiDB = {
    malawiFormID: 12,

    async createMalawiForm( client ) {
        console.log( 'createMalawiForm' );
        const malawiForms = await db.forms.get( malawiDB.malawiFormID );

        let rehabilitation = {
            uuid          : uuidv4(),
            client_uuid   : client.uuid,
            archive       : false,
            rehabilitation: malawiForms,
            synced        : 0
        }

        await formsDB.rehabilitations.add( rehabilitation )

        return rehabilitation
    }

}


