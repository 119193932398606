/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 25.05.23
 * Time: 10:12
 */

import {formsDB} from './formsDB'
import {db} from './db'
import {date, Notify} from 'quasar'
import {v4 as uuidv4} from "uuid"
import {helpers} from "src/services/helpers"
import {persistMobiledata} from "src/services/sync/persistMobiledata"
import {malawiDB} from "src/services/indexedDB/malawiDB"
import formsStore from 'src/store/forms'

export const rehabilitationDB = {

  async getRehabilitation( client, appIdentifier = 'REHAPP' ) {
    if( ! client ) return
    let where = {}
    where.client_uuid = client.uuid
    let rehabilitation = await formsDB.rehabilitations.where( where ).first()

    if ( rehabilitation ) return rehabilitation

    if( appIdentifier == 'REHAPP' ) {
      return await this.createRehabilitation( client )
    }else if( appIdentifier == 'MALA' ) {
      return await  malawiDB.createMalawiForm(client)
    }
  },
  async createRehabilitation( client ) {
    let cycleDomains = await this.getRehabilitationCycledomains( client )
    let ladderOfLive = await rehabilitationDB.getLadderOfLive( client )
    let rehabilitation = {
      uuid          : uuidv4(),
      client_uuid   : client.uuid,
      archive       : false,
      domains       : cycleDomains,
      ladder_of_live: ladderOfLive,
      diary_items   : [],
      visitFrequency: null,
      synced        : 0
    }

    await formsDB.rehabilitations.add( rehabilitation )

    return rehabilitation
  },
  async getRehabilitationCycledomains( client ) {
    let clientFlowform = client.clientFormFields.find( f => f.flowelement )
    if ( !clientFlowform ) return false

    let form = await rehabilitationDB.getForm()
    if ( !form ) return false

    // TODO : this is hardcoded for now, but should be the formpageID for rehabilitation
    let formpageID = false
    if ( clientFlowform.answer == 79 ) {
      formpageID = 3
    } else if ( clientFlowform.answer == 80 ) {
      formpageID = 4
    } else {
      return false
    }

    let formpage = form.formpages.find( fp => fp.id == formpageID )

    let cycleDomains = []
    formpage.cycledomains.forEach( cd => {
      cycleDomains.push( {
        rehappdomain_id: cd.id,
        order          : cd.order,
        description    : cd.translation ? cd.translation.translation : 'NB',
        info           : cd.editortext.translation.translation,
        abilities      : [],
        challenges     : [],
      } )
    } )

    return cycleDomains
  },
  async getLadderOfLive() {
    let form = await rehabilitationDB.getForm()
    if ( !form ) return false

    let formpage = form.formpages.find( fp => fp.formpage_id == 2478 )

    if ( !formpage ) return false

    let elements = []
    let totalColumns = 0
    formpage.formpagelements.forEach( fpe => {
      totalColumns = fpe.metadata.rows + totalColumns
      let newRow = fpe.metadata.rows == 2 ? true : (totalColumns % 2 == 0 ? false : true)
      let itemType = fpe.metadata.itemtype ? fpe.metadata.itemtype.value : ''
      let element = {
        uuid         : uuidv4(),
        editortext   : fpe.editortext ? fpe.editortext.translation : false,
        elementypekey: fpe.elementtype.elementtypekey,
        component    : itemType == 'LADDER' ? 'Ladder' : fpe.elementtype.component,
        columns      : fpe.metadata.columns ? fpe.metadata.columns.value : 1,
        content      : fpe.metadata.content ? fpe.metadata.content.value : '',
        items        : fpe.metadata.items ? fpe.metadata.items.value : '',
        itemtype     : fpe.metadata.itemtype ? fpe.metadata.itemtype.value : '',
        categoryID   : fpe.metadata.categoryID ? fpe.metadata.categoryID.value : 0,
        itemStyle    : fpe.metadata.itemStyle ? fpe.metadata.itemStyle.value : 'ROW',
        mandatory    : fpe.metadata.mandatory ? fpe.metadata.mandatory.value : 0,
        rows         : fpe.metadata.rows ? fpe.metadata.rows.value : 0,
        label        : fpe.metadata.label ? fpe.metadata.label.value : '',
        answer       : ''
      }
      elements.push( element )
    } )

    return elements
  },
  async getForm() {
    let forms = await db.forms.toArray()
    let form = forms.find( f => f.formtype == 'DEFAULT' )
    if ( !form ) return false

    return form;
  },

  async saveRehabilitation( rehabilitation ) {
    rehabilitation = helpers.V2JS( rehabilitation )
    rehabilitation.synced = 0
    await formsDB.rehabilitations.put( rehabilitation )
    persistMobiledata.prepareSync()
  },

  async setFormelement(formElement, elementID){
    let fe = helpers.V2JS( formElement )
    console.log( fe );
    let rehabilitation = formsStore.state.rehabilitation
//     console.log( 'FORMELEMENT',formElement );
//     console.log( 'ELEMENTID',elementID );

    for (const obj of rehabilitation.rehabilitation.formpages) {
      const foundElement =
         obj.formpagelements.find(el => el.id === elementID);
      
      if (foundElement) {
        console.log( 'FOUNDELEMENT',foundElement );
        foundElement.formElement = fe
      }
    }
    console.log( rehabilitation );
    await this.saveRehabilitation(rehabilitation)
  }

}
