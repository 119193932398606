const routes = [
  {
    path     : '/',
    name     : 'start',
    component: () => import('pages/Start.vue'),
    children : []
  },
  {
    path     : '/requestforpassword',
    component: () => import('layouts/Tokenlogin.vue'),
    props    : true
  },
  {
    path     : '/Home',
    component: () => import('layouts/Home.vue'),
    children : [
      {
        path     : '',
        component: () => import('pages/home/Homepage.vue'),
        childen  : [],
        props    : true
      },
      {
        path     : '/Start/:pageID',
        component: () => import('pages/home/Homepage.vue'),
        children : [],
        props    : true
      },
      {
        path     : '/About/:pageID',
        component: () => import('layouts/About.vue'),
        children : [],
        props    : true
      },
      {
        path     : '/Article/:pageID',
        component: () => import('layouts/Article.vue'),
        children : [],
        props    : true
      },
      {
        path     : '/Navigation/:pageID',
        component: () => import('layouts/Navigation.vue'),
        children : [],
        props    : true
      },
    ]
  },
  {
    path     : '/forms',
    component: () => import('layouts/forms/Forms.vue'),
    children : [
      {
        name     : 'clients',
        path     : '/clients',
        component: () => import('layouts/forms/Clients.vue'),
        children : []
      },
      {
        name     : 'cycleform',
        path     : '/Cycleform',
        component: () => import('layouts/forms/cycles/Cycleform.vue'),
        children : []
      },
      {
        name     : 'goalssteps',
        path     : '/goals',
        component: () => import('layouts/forms/cycles/GoalsSteps.vue'),
        props    : true,
        children : []
      },
      {
        name     : 'diary',
        path     : '/diary',
        component: () => import('layouts/forms/cycles/Diary.vue'),
        props    : true,
        children : []
      },
      {
        name: 'clientAppointments',
        path: '/clientAppointments',
//         component: () => import('layouts/forms/cycles/ClientAppointments.vue'),
        component: () => import('layouts/forms/cycles/Diary.vue'),
        props    : true,
        children : []
      }
    ]
  },
  {
    name: 'malawi',
    path: '/malawi',
    component: () => import('layouts/malawi/Malawi.vue'),
    children: []
  },
  {
    name     : 'milestones',
    path     : '/milestones',
    component: () => import('layouts/milestones/Milestones.vue'),
    children : [
      {
        name     : 'milestonesintro',
        path     : '/milestonesintro',
        component: () => import('layouts/milestones/MilestonesIntro.vue'),
        childen  : [],
        props    : true
      },
      {
        name     : 'milestonechildren',
        path     : '/milestonechildren',
        component: () => import('layouts/milestones/Milestonechildren.vue'),
        childen  : [],
        props    : true
      },
      {
        name     : 'milestonedomains',
        path     : '/milestonedomains',
        component: () => import('layouts/milestones/Milestonedomains.vue'),
        childen  : [],
        props    : true
      },
      {
        name     : 'milestonecategories',
        path     : '/milestonecategories',
        component: () => import('layouts/milestones/Milestonecategories.vue'),
        childen  : [],
        props    : true
      },
      {
        name     : 'milestoneindicators',
        path     : '/milestoneindicators/:categoryID',
        component: () => import('layouts/milestones/Milestoneindicators.vue'),
        childen  : [],
        props    : true
      }
    ]
  },
  {
    name     : 'hiapp',
    path     : '/hiapp',
    component: () => import('layouts/hiapp/Hiapp.vue'),
    children : []
  },
  {
    path     : '/calendar',
    component: () => import('layouts/calendar/Calendar.vue'),
    children : [
      {
        path     : '',
        component: () => import('layouts/calendar/WeekCalendar.vue'),
        childen  : [],
        props    : true
      }
    ]
  },
  {
    path     : '/chat',
    component: () => import('layouts/settings/Chat.vue'),
    children : []
  },
  {
    path     : '/settings',
    component: () => import('layouts/settings/Settings.vue'),
    children : []
  },
  {
    path     : '/initialize',
    component: () => import('layouts/Initialize.vue'),
    children : [
      {
        path     : '',
        component: () => import('pages/initialize/Welcome.vue'),
        childen  : []
      },
      {
        path     : '/language',
        component: () => import('pages/initialize/Language.vue'),
      },
      {
        path     : '/region',
        component: () => import('pages/initialize/Region.vue'),
      },
      {
        path     : '/recap',
        component: () => import('pages/initialize/Recap.vue'),
      }
    ]
  },
  {
    path     : '/forms',
    component: () => import('layouts/forms/Forms.vue'),
    children : []
    // Always leave this as last one,
    // but you can also remove it
  },
  {
    path     : '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
