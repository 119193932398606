import {store} from 'quasar/wrappers'
import {createStore} from 'vuex'

import articles from './articles'
import milestones from './milestones'
import hiapp from './hiapp'
import forms from './forms'
import {api} from '../boot/axios'
import {logoutService} from 'src/services/sync/logoutService'
import {db} from 'src/services/indexedDB/db'
import {setCssVar} from 'quasar'
import {helpers} from "src/services/helpers"
import {speechService} from "src/services/speech/speechService"

// import example from './module-example'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store( function (/* { ssrContext } */ ) {
  let initBusy = false
  const Store = createStore( {
    state    : {
      vLeftDrawerOpen   : false,
      vRightDrawerOpen  : true,
      initialized       : null,
      settings          : {},
      languages         : [],
      regions           : [],
      modalLogin        : false,
      modalNewEditClient: false,
      modalClientinfo   : false,
      modalFormpage     : false,
      modalTexteditor   : false,
      loggedIn          : false,
      user              : false,
      validPincode      : false,
      onlinestatus      : false,
      changepassword    : false,
      translations      : [],
      applicationID     : null,
      appData           : {},
      knownForms        : ['DEFAULT', 'MILESTONE', 'HI'],
      speechBusy        : false
    },
    mutations: {
      setLanguages( state, payload ) {
        state.languages = payload
      },
      setRegions( state, payload ) {
        state.regions = payload
      },
      setApplicationID( state, applicationID ) {
        state.applicationID = applicationID
      },
      setOnlinestatus( state, status ) {
        state.onlinestatus = status
      },
      closeDrawers( state ) {
        state.vLeftDrawerOpen = false
        state.vRightDrawerOpen = false
      },
      setLeftDrawerOpen( state ) {
        state.vLeftDrawerOpen = !state.vLeftDrawerOpen
      },
      setRightDrawerOpen( state ) {
        state.vRightDrawerOpen = !state.vRightDrawerOpen
      },
      setInitialized( state, status ) {
        state.initialized = status
      },
      setTranslations( state, translations ) {
        state.translations = translations
      },
      setModal( state, modal ) {
        state[modal] = !state[modal]
      },
      createLoginState( state, payload ) {
        state.loggedIn = payload
      },
      setUser( state, payload ) {
        state.user = payload
      },
      setValidPincode( state, status ) {
        state.validPincode = status
      },
      setAppData( state, appData ) {
        if ( !appData ) {
          for ( const key in state.appData ) {
            state.appData[key] = ''
          }
        } else {
          for ( const key in appData ) {
            state.appData[key] = appData[key]
          }
          switch ( state.appData.appIdentifier ) {
            case 'HIAPP':
              setCssVar( 'primary', '#0077c8' )
              setCssVar( 'secondary', '#e5221e' )
              setCssVar( 'accent', '#0345ea' )
              break;
            case 'MALA':
              setCssVar( 'primary', '#AC3B61' )
              setCssVar( 'secondary', '#EDC7B7' )
              setCssVar( 'accent', '#123C69' )
              break;
            default:
              setCssVar( 'primary', '#d53b16' )
              setCssVar( 'secondary', '#16B0D5' )
              setCssVar( 'accent', '#D51650' )
          }
        }
      },
      setSettings( state, payload ) {
        for ( var key of Object.keys( payload ) ) {
          state.settings[key] = payload[key]
        }
        localStorage.setItem( 'settings', JSON.stringify( state.settings ) )
      },
      setSetting( state, payload ) {
        let settings = JSON.parse( localStorage.getItem( 'settings' ) || [] )

        settings[payload.type] = payload.value

        localStorage.setItem( 'settings', JSON.stringify( settings ) )
        state.settings[payload.type] = payload.value
      },
      setSpeechBusy( state, payload ) {
        state.speechBusy = payload
      }
    },
    actions  : {
      async getInitApplication( context, languageCode ) {
        let appID = localStorage.getItem( 'applicationID' )
        let result = await api.get( '/api/applications/getInitApplication/' + appID + '/' + languageCode )

        context.commit( 'setLanguages', result.data.languages )
        context.commit( 'setRegions', result.data.regions )

        localStorage.setItem( 'languages', JSON.stringify( result.data.languages ) )
        localStorage.setItem( 'regions', JSON.stringify( result.data.regions ) )
      },
      getVersion() {
        let payload = {
          url: window.location.host
        }
        let formData = new FormData()
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/mobile/version', formData )
          .then( result => {
            return result
          } )
      },
      async getInitApplicationByUrl( context ) {
        if ( initBusy ) return
        let payload = {
          url: window.location.host
        }
        let formData = new FormData()
        formData.append( 'formData', JSON.stringify( payload ) )

        let result = await api.post( '/api/applications/getInitApplication', formData )

        context.commit( 'setLanguages', result.data.languages )
        context.commit( 'setRegions', result.data.regions )
        context.commit( 'setApplicationID', result.data.id )
        let metadata = JSON.parse( result.data.metadata )

        //Store appData in localStorage
        let appData = {
          appIdentifier: result.data.app_identifier,
          appTitle     : result.data.app_title,
          appLogin     : result.data.login == 1 ? true : false,
          text2speech  : metadata.text2speech ? true : false,
          userLogin    : metadata.userLogin ? true : false,
        }

        localStorage.setItem( 'version', result.data.version )
        localStorage.setItem( 'applicationID', result.data.id )
        localStorage.setItem( 'APP-DATA', JSON.stringify( appData ) )
        localStorage.setItem( 'languages', JSON.stringify( result.data.languages ) )
        localStorage.setItem( 'regions', JSON.stringify( result.data.regions ) )
        if ( metadata.introText ) localStorage.setItem( 'introText', JSON.stringify( metadata.introText ) )

        // Each form (Default, Milestones, HI) has a formtype.
        // If this formtype is available for the app is stored in localStorage  (0,1).
        context.state.knownForms.forEach( kf => {
          let formStatus = result.data.forms.find( f => f.formtype == kf )
          localStorage.setItem( kf, formStatus ? 1 : 0 )
        } )

        context.dispatch( 'setAppData', appData )

        return result
      },
      setAppData( context, appData ) {
        context.commit( 'setAppData', appData )
      },
      setOnlinestatus( context, status ) {
        context.commit( 'setOnlinestatus', status )
      },
      closeDrawers( context ) {
        context.commit( 'closeDrawers' )
      },
      toggleLeftDrawerOpen( context ) {
        context.commit( 'setLeftDrawerOpen' )
      },
      toggleRightDrawerOpen( context ) {
        context.commit( 'setRightDrawerOpen' )
      },
      setTranslations( context ) {
        db.getAllTranslations()
          .then( translations => {
            context.commit( 'setTranslations', translations )
          } )
      },
      setInitialize( context, status ) {
        context.commit( 'setInitialized', status )
      },
      setSettings( context, payload ) {
        context.commit( 'setSettings', payload )
      },
      toggleModal( context, modal ) {
        context.commit( 'setModal', modal )
      },
      async getLanguages( context, languagecode ) {
        let result = await api.get( '/api/getTranslatedLanguages/' + languagecode )

        return result.data
      },
      createLoginState( context, logInState ) {
        if ( logInState === false ) {
          logoutService.logout()
        } else {
          localStorage.setItem( 'loggedIn', logInState )
          context.commit( 'createLoginState', logInState )
        }
      },
      setUser( context, payload ) {
        let user = localStorage.getItem( 'user' )
        if ( !user || user == 'undefined' ) return context.commit( 'setUser', null )

        context.commit( 'setUser', JSON.parse( user ) )
      },
      setPassword( context, form ) {
        return api.post( '/api/users/setpassword', form )
      },
      setValidPincode( context, status ) {
        localStorage.setItem('pincodeValid', status)
        context.commit( 'setValidPincode', status )
      },
      setSetting( context, payload ) {
        context.commit( 'setSetting', payload )
      },
      getTranslationsByIdentifier( context, identifier ) {
        let translation = context.state.translations.find( o => o.identifier == identifier )

        return translation ? translation.translation : 'NB'
      },
      speakItOutLoud( context, text ) {
        context.commit( 'setSpeechBusy', true )
        speechService.speakIt( text )
          .then( () => {
            context.commit( 'setSpeechBusy', false )
          } )
      }
    },
    getters  : {
//       getTranslationsByIdentifier( state, identifier ) {
//         let translation = state.translations.find( o => o.identifier == identifier )
//
//         return translation ? translation.translation : 'NB'
//       }
    },
    modules  : {
      articles  : articles,
      forms     : forms,
      milestones: milestones,
      hiapp     : hiapp
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING
  } )

  return Store
} )

