// db.js
import Dexie from 'dexie'
import {useStore} from "vuex"
import {v4 as uuidv4} from "uuid"
import {db} from "src/services/indexedDB/db"
import {persistMobiledata} from "src/services/sync/persistMobiledata"
import forms from 'src/store/forms'


export const formsDB = new Dexie( 'forms' );

formsDB.version( 18 ).stores( {
  clients          : '++id, uuid, archive, synced',
  client_milestones: '++id, uuid, client_uuid, synced',
  removed_items    : '++id, synced',
  rehabilitations  : '++id, uuid, client_uuid, synced',
  version          : ''
} );

formsDB.getAllClients = async function () {
  return await formsDB.clients.toArray();
}

formsDB.getClientDisabilities = async function ( clientUUID, category ) {
  let forms = await db.forms.toArray()

  let form = forms.find( f =>  f.formtype == 'DEFAULT')
  if ( !form ) return null

  let clientFormpage =
        form.formpages.find( fp => fp.formtype == 'CLIENTFORM' )
  if ( !clientFormpage ) return null

  let disability = clientFormpage.formpagelements.find( fpe => fpe.entity == 'DISABILITY' )

  let clientelement = await formsDB.getClientelement( clientUUID, disability.id )

  return clientelement
}

formsDB.getValidForm = async function ( clientUUID ) {
  let clientElements =
        await formsDB.clientelements.where( {client_uuid: clientUUID} ).toArray()

  let valid = true
  clientElements.forEach( ce => {
    if ( ce.mandatory == 0 ) return
    if ( ce.answer.length > 0 || Number.isInteger( ce.answer ) ) return

    valid = false
  } )

  return valid
}

formsDB.setCheckbox = async function ( cats ) {
  if ( typeof cats == 'number' ) cats = [cats]
  let catNames = ''
  let categories = await db.categories.toArray()
  cats.forEach( c => {
    categories.forEach( ca => {
      let foundItem = ca.categoryitems.find( cat => {
        return c == cat.id
      } )
      if ( foundItem ) {
        if ( catNames.length > 0 ) catNames += ', '
        catNames += foundItem.translation ? foundItem.translation.translation : ''
      }
    } )
  } )
  return catNames
}

formsDB.saveActiveClient = async function ( client ) {
  let c = formsDB.vueToObj( client )
  let clientData = await formsDB.clients.where( {uuid: c.uuid} ).modify( c )
}

formsDB.vueToObj = function ( vueObject ) {
  return JSON.parse( JSON.stringify( vueObject ) )
}

formsDB.setStaleData = function () {
  localStorage.setItem( 'stale_data', 'T' )
  persistMobiledata.prepareSync()
}


// defaultpages: '',
//   categories : 'id',
//   forms: 'id'
